import { useEffect } from 'react';

const COOKIE_CONSENT_ID = 'CookieConsent';
const COOKIE_CONSENT_URL = 'https://policy.app.cookieinformation.com/uc.js';
const COOKIE_CONSENT_DATA_CULTURE = 'DA';
const COOKIE_CONSENT_DATA_GCM_VERSION = '2.0';

const CookieConsent = (): null => {
  useEffect(
    (): void => {
      if (document.getElementById(COOKIE_CONSENT_ID)) {
        return;
      }

      const script = document.createElement('script');

      script.id = COOKIE_CONSENT_ID;
      script.src = COOKIE_CONSENT_URL;

      script.setAttribute('data-culture', COOKIE_CONSENT_DATA_CULTURE);
      script.setAttribute('data-gcm-version', COOKIE_CONSENT_DATA_GCM_VERSION);

      script.setAttribute('type', 'text/javascript');
      document.body.appendChild(script);
    },
    [],
  );

  return null;
};

export default CookieConsent;
